import React, { useState, useEffect } from 'react';
import './App.sass';
import './App.css';

function App() {
  const [quote, setQuote] = useState("");

  useEffect(() => {
    fetch('http://quotes.rest/qod.json?category=funny')
    .then(e => e.json())
    .then(r => {
      // console.log(r.contents.quotes[0].quote.toString())
      setQuote(r.contents.quotes[0].quote.toString())

    })
    .catch(e => console.error(e))
  }, [])

  return (
    <div className="container">
      <section className="hero">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">
            Daniel Audino
            </h1>
            <h2 className="subtitle">
              <button className="central button is-primary">Donate</button>
            </h2>
          </div>
          <p>{quote}</p>
        </div>
      </section>
    </div>
  );
}

export default App;
